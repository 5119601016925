@media screen and (min-width: 993px) {
  .custom-height {
    height: 400px;
  }

  .page-header {
    height: 420px;
  }

  .page-header-placement {
    top: 180px;
  }

  .big-menu {
    position: relative;
    left: 8.4%;
  }
  .search-place.index {
    right: -15.4%;
  }

  .item-inner-text {
    font-size: 17px;
    font-weight: bold;
  }

  .hide-burger-menu {
    display: none;
  }

  .hide-main-menu {
    display: flex;
  }

  .card-img,
  .card-img-top {
    height: 200px;
  }

  .image-style-side {
    max-width: 50%;
    float: right;
  }

  .videoSize {
    width: 100%;
    height: 80vh;
  }

  .mapSize {
    height: 600px;
  }
}

@media (max-width: 992px) {
  .no-card {
    display: none;
  }
  .card-img-management {
    height: auto;
  }

  .image-style-side {
    max-width: 100%;
  }
}

@media (min-width: 769px) and (max-width: 992px) {
  .box-2 h3 {
    font-size: 13px;
  }

  .videoSize {
    width: 100%;
    height: 300px;
  }
}

@media (min-width: 577px) and (max-width: 768px) {
  .box-2 h3 {
    font-size: 10px;
  }

  .videoSize {
    width: 100%;
    height: 300px;
  }

  .mapSize {
    height: 300px;
  }

  .service-card {
    height: auto;
  }

  .service-img {
    height: auto;
  }

  .service-title {
    height: auto;
    font-size: 16px;
  }

  .card-img,
  .card-img-top {
    max-height: 200px;
  }
}

@media screen and (max-width: 992px) {
  .page-header {
    height: 350px;
  }

  .big-menu-item-icon {
    width: 20%;
  }

  .big-menu-item-inner-text {
    font-size: 12px;
  }

  .hide-burger-menu {
    display: none;
  }

  .hide-main-menu {
    display: flex;
  }

  .box-2 {
    font-size: 10px;
  }
}

@media screen and (max-width: 768px) {
  .service-office {
    height: auto;
  }

  .service-department {
    height: auto;
  }

  .service-management {
    height: auto;
  }

  .page-header {
    height: 300px;
  }

  .footer-content {
    display: none;
  }

  .big-menu-item-icon {
    width: 20%;
  }

  .big-menu-item-inner-text {
    font-size: 10px;
  }

  .hide-burger-menu {
    display: none;
  }

  .hide-main-menu {
    display: flex;
  }
}

@media (max-width: 576px) {
  .big-menu-background {
    background-size: cover;
    background-position: center center;
  }

  .article {
    margin-right: -15px;
    margin-left: -15px;
  }

  .article-files {
    margin-right: -15px;
    margin-left: -15px;
  }

  .videoSize {
    width: 100%;
    height: 200px;
  }

  .footer ul.footer-menu {
    margin: 15px 38px 20px auto;
  }

  .footer ul.footer-menu > li {
    margin: 0;
    text-align: center;
  }

  .page-header {
    height: 280px;
  }

  .box-1 {
    width: auto;
  }

  .main-logo {
    margin: 60px 0 0 0;
    width: 50%;
  }

  .navbar ul {
    background-color: #fff;
  }

  .navbar-toggler {
    border: none;
    outline: 0;
    margin-right: 10px;
  }

  .navbar-toggler:focus {
    outline: 0;
  }

  .nav-item {
    display: block-inline;
  }

  .hide-main-menu {
    display: none;
  }

  .hide-burger-menu {
    display: flex;
    padding-left: 15px;
    height: 500px;
    max-height: 500px;
    width: 100%;
    overflow-y: scroll;
  }

  .small-menu-icon {
    position: relative;
    top: 55px;
    left: 33%;
  }

  .small-menu-item-text {
    position: relative;
    top: 55px;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 2px;
    padding-bottom: 2px;
    text-align: center;
  }
}

@media screen and (max-width: 425px) {
  .small-menu-icon {
    position: relative;
    top: 35px;
    left: 30%;
  }

  .small-menu-item-text {
    position: relative;
    top: 30px;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 2px;
    padding-bottom: 2px;
    text-align: center;
  }

  .main-logo {
    margin: 100px 0 0 0;
    width: 40%;
  }

  .big-menu-background {
    height: 300px;
  }
}

@media screen and (max-width: 375px) {
  .navbar-item-news {
    width: 185px;
    height: 160px;
  }

  .navbar-item-services {
    width: 190px;
    height: 160px;
  }

  .navbar-item-business {
    width: 185px;
    height: 160px;
  }

  .navbar-item-citizen {
    width: 190px;
    height: 160px;
  }

  .navbar-item-animals {
    width: 185px;
    height: 160px;
  }

  .navbar-item-tourism {
    width: 190px;
    height: 160px;
  }

  .small-menu-icon {
    position: relative;
    top: 35px;
    left: 30%;
  }

  .small-menu-item-text {
    position: relative;
    top: 30px;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 2px;
    padding-bottom: 2px;
    text-align: center;
  }

  .main-logo {
    margin: 100px 0 0 0;
    width: 50%;
  }

  .big-menu-background {
    height: 300px;
  }
}

@media screen and (max-width: 320px) {
  .navbar-item-news {
    width: 160px;
    height: 160px;
  }

  .navbar-item-services {
    width: 160px;
    height: 160px;
  }

  .navbar-item-business {
    width: 160px;
    height: 160px;
  }

  .navbar-item-citizen {
    width: 160px;
    height: 160px;
  }

  .navbar-item-animals {
    width: 160px;
    height: 160px;
  }

  .navbar-item-tourism {
    width: 160px;
    height: 160px;
  }

  .small-menu-icon {
    position: relative;
    top: 35px;
    left: 30%;
  }

  .small-menu-item-text {
    position: relative;
    top: 30px;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 2px;
    padding-bottom: 2px;
    text-align: center;
  }

  .main-logo {
    margin: 100px 0 0 0;
    width: 60%;
  }

  .big-menu-background {
    height: 300px;
  }
}
