@charset "UTF-8";
*,
*:after,
*:before {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

html {
  font-size: 62.5%;
}

body {
  font-family: "Open Sans", sans-serif;
  padding: 0;
  margin: 0;
  position: relative;
  font-size: 17px;
  color: #4c4a4a;
  text-align: center;
  background: rgba(14, 22, 34, 0.02);
}

ul,
ol {
  text-align: start;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
}

a {
  color: #fff;
  text-decoration: none;
}

p a {
  color: #fff;
  text-decoration: underline;
}

.editor div {
  margin-top: 20px;
}

.editor a {
  color: #007bff;
  text-decoration: none;
}

.editor a:hover {
  color: #007bff;
  text-decoration: none;
}

a:hover {
  color: #fff;
  text-decoration: none;
}

a:focus {
  outline: 0;
}

a:hover,
a:active {
  outline: 0;
}

.hr {
  border: 2px solid #112e57;
}

input:focus {
  outline: 1px solid #04a4cc;
}

.small-logo {
  height: 60px;
}

.footer-img {
  height: 200px;
}

.portrait {
  width: 200px;
  margin-right: 10px;
  float: left;
}

.profile-page {
  background-size: cover;
  width: 100%;
  max-height: 240px;
}

.round-image {
  width: 70px;
  height: 70px;
  border-radius: 100%;
}

.small-menu-icon {
  /* border-radius: 25%; */
  /* border: 3px solid #fff; */
}

.loader {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #112e57;
  border-radius: 50%;
  width: 130px;
  height: 130px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.page-link {
  color: #222222 !important;
}

.page-item.active .page-link {
  color: #fff !important;
  background-color: #222222;
  border-color: #222222;
}

.aligncenter {
  display: block;
  margin: 5px auto 5px auto;
}

.react-player {
  background-color: #000;
  margin: 0 auto;
}

/* Main Logo */
.main-logo {
  width: 35%;
  margin-top: 15%;
}

.add-padding {
  padding-bottom: 20px;
}

.add-plr {
  padding-left: 18px;
  padding-right: 18px;
}

.add-padding-top-bottom {
  padding-top: 50px;
  padding-bottom: 50px;
  padding-left: 0;
  padding-right: 0;
}

.sub-title {
  color: #112e57;
  /* font-size: 37px; */
  text-transform: uppercase;
  letter-spacing: 2px;
  padding-bottom: 40px;
  font-weight: bold;
}

.sub-title-2 {
  color: #112e57;
  /* font-size: 25px; */
  text-transform: uppercase;
  letter-spacing: 2px;
  padding-bottom: 20px;
  font-weight: bold;
  border-bottom: 3px solid #112e57;
}

.sub-title-3 {
  color: #112e57;
  /* font-size: 25px; */
  text-transform: uppercase;
  letter-spacing: 2px;
  padding-top: 40px;
  font-weight: bold;
}

.sub-title-4 {
  color: #112e57;
  /* font-size: 25px; */
  text-transform: uppercase;
  letter-spacing: 2px;
  padding-bottom: 40px;
  font-weight: bold;
}

.sub-title-5 {
  color: #112e57;
  /* font-size: 25px; */
  text-transform: uppercase;
  letter-spacing: 2px;
  padding-bottom: 20px;
  font-weight: bold;
  border-bottom: 3px solid #112e57;
  margin-bottom: 30px;
}

.sub-title-6 {
  color: #112e57;
  /* font-size: 25px; */
  text-transform: uppercase;
  letter-spacing: 2px;
  padding-bottom: 20px;
  font-weight: bold;
  border-bottom: 3px solid #112e57;
}

.sub-title-7 {
  color: #112e57;
  text-transform: uppercase;
  letter-spacing: 2px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 3px solid #112e57;
}

/* .bg-cal {
  background-image: url("/img/dsc06646_copy.jpg");
  background-size: cover;
  height: 100%;
  background-position: center center;
  overflow: hidden;
  padding: 30px;
} */

.page-header {
  position: relative;
  background-color: #6495ed;
  /* background-image: url("/img/home-bg.jpg"); */
  background-size: cover;
  height: 100%;
  background-position: center center;
  overflow: hidden;
  margin-bottom: 50px;
  /* border-bottom: 5px solid #112e57; */
}

.page-header-placement {
  position: absolute;
  left: 0;
  z-index: 2;
  margin: 5px auto 5px auto;
  width: 100%;
  top: 120px;
}

.page-header-title {
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
}

.page-header-image {
  width: 150px;
  height: auto;
}

.overlay {
  position: relative;
}
.overlay:before {
  position: absolute;
  content: "";
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: #000;
  opacity: 0.6;
}

/*
=========================

=========================
*/

/*
=========================
    BIG MENU START
=========================
*/
.big-menu-background {
  background-size: contain;
  /* background-position: center center; */
  background-color: #292c31;
  overflow: hidden;
  white-space: nowrap;
  margin-bottom: 30px;
}

.big-menu-item-inner-text {
  bottom: 35%;
  color: #fff;
  letter-spacing: 2px;
  display: inline-block;
  position: absolute;
  min-width: 3em;
  padding: 0px;
  color: #fff;
  letter-spacing: 2px;
  width: 100%;
}

.big-menu-item-icon {
  position: relative;
  margin: 0 auto;
  display: block;
}

.big-menu-item-bg {
  width: 100%;
  height: auto;
}

.big-menu-item-text {
  text-transform: uppercase;
  letter-spacing: 2px;
  padding-bottom: 2px;
  font-weight: 600;
}
/*
=========================
    BIG MENU END
=========================
*/

/*
=========================
    NAVBAR START
=========================
*/
.bg-blue {
  background-color: #112e57 !important;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
}

.navbar-block {
  display: block !important;
  flex-basis: auto;
}

.navbar-brand {
  padding-left: 10px;
  padding-top: 0;
  padding-bottom: 0;
}

.navbar-item-news {
  width: 200px;
  height: 200px;
  background-color: #a7470d;
}

.navbar-item-services {
  width: 200px;
  height: 200px;
  background-color: #109961;
}

.navbar-item-business {
  width: 200px;
  height: 200px;
  background-color: #0f42a4;
}

.navbar-item-tourism {
  width: 200px;
  height: 200px;
  background-color: #90581f;
}

.navbar-item-citizen {
  width: 200px;
  height: 200px;
  background-color: #0baad4;
}

.navbar-item-animals {
  width: 200px;
  height: 200px;
  background-color: #660101;
}

/*
=========================
    NAVBAR END
=========================
*/

/*
=========================
    FOOTER START
=========================
*/

.footer {
  background-color: #222222;
  min-height: 275px;
  text-align: left;
  margin: 0 auto;
  padding-top: 30px;
  /* Footer menu */
}
.footer .footer-menu-container {
  width: 100%;
  text-align: center;
  margin: 65px auto 75px auto;
}
.footer ul.footer-menu {
  list-style-type: none;
  display: grid;
  margin: 0 auto 20px auto;
  line-height: 200%;
  text-align: center;
}
.footer a {
  text-transform: uppercase;
  color: #fff;
  padding: 5px;
  font-size: 14px;
  text-align: left;
  border-bottom: 1px solid #222222;
  transition: all 0.3s ease-in-out;
}

.footer a:hover {
  border-bottom: 1px solid #fff;
  letter-spacing: 2px;
  transition: all 0.3s ease-in-out;
}

.footer h2 {
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
  border-bottom: 2px solid #fff;
}

.footer ul.footer-menu ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.footer ul.footer-menu > li {
  display: inline-block;
  vertical-align: top;
  text-align: left;
  margin: 0 40px;
}
.footer ul.footer-menu > li a {
  list-style: none;
  text-align: left;
  padding: 0;
  letter-spacing: 2px;
  line-height: 200%;
  display: inline-block;
}
.footer p {
  display: inline-block;
  margin: 20px;
  font-size: 15px;
  color: #ffffff;
  letter-spacing: 1px;
}
.footer .footer-copyright {
  text-align: center;
}

/*
=========================
    FOOTER END
=========================
*/

/*
=========================
    REACT-CALENDAR START
=========================
*/
.react-calendar {
  width: 100% !important;
  border: none !important;
}

.abbr[data-original-title],
abbr[title] {
  text-decoration: none;
}

.react-calendar__month-view__days__day--weekend {
  /* color: red !important; */
}

.react-calendar__month-view__days__day--weekend:active {
  color: initial !important;
}

.react-calendar__tile--now {
  background: #112e57 !important;
  color: white;
}
/*
=========================
    REACT-CALENDAR END
=========================
*/

/*
=========================
    CARD START
=========================
*/

.article {
  padding: 20px;
  background-color: #fff;
  box-shadow: rgb(227, 233, 243) 0px 2px 4px 0px;
  border-radius: 3px;
  margin: 10px;
  overflow: hidden;
}

.style2 {
  padding: 20px;
  background-color: #fff;
  box-shadow: rgb(227, 233, 243) 0px 2px 4px 0px;
  border-radius: 3px;
}

.article-files {
  margin: 10px;
  padding: 20px;
  background-color: #fff;
  box-shadow: rgb(227, 233, 243) 0px 2px 4px 0px;
  border-radius: 3px;
}

.card-img,
.card-img-top {
  height: 200px !important;
}

.card-img,
.card-img-event {
  height: auto;
}

.card {
  margin-left: -5px;
  margin-right: -5px;
  margin-top: 12px;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  border-radius: 3px;
  transition: all 0.3s ease-in-out;
}

.card:hover {
  border-radius: 20px;
  transition: all 0.3s ease-in-out;
}

.card-text {
  text-align: start;
  color: #4c4a4a;
  height: 20px;
  text-transform: uppercase;
  overflow: hidden;
  font-weight: 600;
  transition: all 0.3s ease-in-out;
}

.card:hover .card-text {
  color: #222222;
  transition: all 0.3s ease-in-out;
}

.card:hover .card-title {
  color: #222222;
  transition: all 0.3s ease-in-out;
}

.card:hover .card-date {
  color: #fff;
  background-color: #222222;
  transition: all 0.3s ease-in-out;
}

.card-body {
  padding: 20px 20px 20px 20px;
}

.card-title {
  font-size: 19px;
  text-align: start;
  color: #212529;
  /* border-top: 1px solid #ccc; */
  border-bottom: 1px solid #ccc;
  padding-top: 0px;
  padding-bottom: 20px;
  height: 130px;
  overflow: hidden;
  margin-bottom: 13px;
  transition: all 0.3s ease-in-out;
}

.card-title-2 {
  font-size: 19px;
  text-align: start;
  color: #212529;
  /* border-top: 1px solid #ccc; */
  border-bottom: 1px solid #ccc;
  padding-top: 0px;
  padding-bottom: 20px;
  overflow: hidden;
  margin-bottom: 13px;
  transition: all 0.3s ease-in-out;
}

.card-body-management {
  /* background-color: #132e57; */
}

.card-title-management {
  font-size: 19px;
  text-align: center;
  color: #222222;
  padding-top: 0px;
  padding-bottom: 20px;
  height: 85px;
  overflow: hidden;
  margin-bottom: 13px;
  transition: all 0.3s ease-in-out;
  font-weight: 500;
}

.card-img-management {
  height: 200px;
}

.card-department {
  background-color: #fff;
  margin: 5px 10px 5px 10px;
  overflow: hidden;
  box-shadow: rgb(227, 233, 243) 0px 2px 4px 0px;
  border-radius: 3px;
}

.card-img-department {
  height: 70px;
  width: 100%;
}

.card-title-department {
  color: #222222;
  text-align: center;
  font-size: medium;
  overflow: hidden;
  padding: 10px;
  font-weight: 500;
}

.card-date {
  background: #292c31;
  transition: all 0.3s ease-in-out;
}

.links-card {
  margin: 10px;
  overflow: hidden;
  box-shadow: rgb(227, 233, 243) 0px 2px 4px 0px;
  border-radius: 3px;
}

.action-card {
  margin-bottom: 10px;
  overflow: hidden;
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.3);
}

.service-card {
  height: auto;
  margin: 10px;
  margin-bottom: 50px;

  /* background-color: #fff;
  color: #112e57;
  padding-bottom: 20px; */
}

.service-management {
  height: auto;
  width: 100%;
  padding: 15px;
  box-shadow: rgb(227, 233, 243) 0px 2px 4px 0px;
  /* border: 2px solid #ccc; */
  border-radius: 3px;
  background-color: #fff;
  color: #222222;
  font-size: 19px;
  margin-top: 20px;
  font-weight: bold;
}

.service-management div,
.service-management {
  transition: all 0.5s ease-in-out;
}

.service-department {
  height: 75px;
  width: 100%;
  margin-top: 13px;
  padding: 15px;
  box-shadow: rgb(227, 233, 243) 0px 2px 4px 0px;
  /* border: 2px solid #ccc; */
  border-radius: 3px;
  background-color: #fff;
  color: #222222;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
}

.service-department:hover {
  /* border-bottom: 2px solid #112e57;
  border-left: 2px solid #ccc; */
}

.service-department div,
.service-department {
  transition: all 0.5s ease-in-out;
}

.service-office {
  height: 65px;
  width: 100%;
  margin-top: 13px;
  padding: 15px;
  box-shadow: rgb(227, 233, 243) 0px 2px 4px 0px;
  border: 2px solid rgba(14, 22, 34, 0.02);
  background-color: #fff;
  color: #222222;
  font-size: 15px;
  font-weight: 500;
  text-align: left;
  border-radius: 3px;
}

.service-office:hover {
  border-bottom: 2px solid #222222;
}

.service-office div,
.service-office {
  transition: all 0.5s ease-in-out;
}

.service-submenu {
  height: 85px;
  padding: 20px;
  margin-top: 20px;
  border-bottom: 1px solid #ccc;
  border-top: 1px solid #ccc;
  background-color: #fff;
  color: #222222;
  font-size: 14px;
}

.service-submenu ul {
  list-style: none;
}

/* .service-card:hover div, .service-card:hover {
  background-color: #112e57;
  color: #fff;
  transition: all 0.5s ease-in-out;
} */

.service-img {
  height: 190px;
  width: 100%;
}

.municipality-title {
  height: 50px;
  width: 100%;
  font-size: 19px;
  font-weight: 500;
  padding: 15px;
  text-transform: uppercase;
}

.municipality-card {
  height: 60px;
  margin: 10px;
  border: 2px solid #012e57;
  background-color: #fff;
  border-radius: 3px;
  background-color: #012e57;
  transition: all 0.5s ease-in-out;
}

.municipality-card div,
.municipality-card {
  transition: all 0.5s ease-in-out;
}

.municipality-card:hover {
  border: 2px solid #222222;
  background-color: #222222;
  transition: all 0.5s ease-in-out;
}

.citizen-title p {
  padding: 3px;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 55%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.citizen-card {
  height: 90px;
  margin: 10px;
  background-color: #112e57;
  color: #fff;
  padding: 10px;
  border-radius: 7px;
  border: 1px solid #ccc;
}

.citizen-card div,
.citizen-card {
  transition: all 0.5s ease-in-out;
}

.citizen-card:hover {
  border-radius: 20px;
  transition: all 0.5s ease-in-out;
}

.citizen-img {
  margin: 0;
  position: absolute;
  top: 25%;
  -ms-transform: translate(-50%);
  transform: translate(-50%);
  border-radius: 100%;
  border: 3px solid #fff;
}

.mana-card {
  margin: 10px;
  background-color: #112e57;
  color: #ffffff;
  padding: 0px;
  border-radius: 3px;
  border: 1px solid #ccc;
}

.mana-title {
  text-align: center;
  padding: 10px;
  height: 100px;
  overflow: hidden;
  text-transform: uppercase;
}

.mana-card div,
.mana-card {
  transition: all 0.5s ease-in-out;
}

.mana-card:hover {
  border-radius: 20px;
  transition: all 0.5s ease-in-out;
}

.dmt-card {
  margin: 10px;
  color: #ffffff;
  padding: 0px;
  border-radius: 3px;
  border: 1px solid #ccc;
}

.dmt-title {
  text-align: start;
  padding: 10px;
  height: 100px;
  overflow: hidden;
  text-transform: uppercase;
}

.dmt-card div,
.dmt-card {
  transition: all 0.5s ease-in-out;
}

.dmt-card:hover {
  border-radius: 20px;
  transition: all 0.5s ease-in-out;
}

.tourism-card {
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.3);
  border-bottom: 2px solid #fff;
  transition: all 0.3s ease-in-out;
  margin: 5px;
}

.tourism-card:hover {
  border-bottom: 2px solid #222222;
  transition: all 0.3s ease-in-out;
}

.tourism-text {
  position: absolute;
  bottom: 55%;
  left: 0;
  font-size: 23px;
  color: #fff;
  width: 100%;
  padding: 0;
}

.tourism-btn {
  transition: all 0.3s ease-in-out;
  width: 100%;
  display: block;
  padding: 20px 20px;
  font-size: 16px;
  text-transform: capitalize;
  border-top: 1px solid #dfdfdf;
  color: #333333;
  font-weight: 600;
  outline: none;
  text-decoration: none;
  background-color: transparent;
  text-align: start;
}

.tourism-btn:hover {
  color: #222222;
  text-decoration: none;
}

.tourism-btn i {
  float: right;
  font-size: 22px;
}

/*
==========================
    CARD END
==========================
*/

/*
==========================
    BUTTONS START
==========================
*/
.more-btn {
  width: 200px;
  margin: 45px auto 0 auto;
  height: 50px;
  padding: 13px;
  box-shadow: rgb(227, 233, 243) 0px 2px 4px 0px;
  border-radius: 3px;
  font-size: 15px;
  font-weight: bold;
  background-color: #212529;
}

.more-btn-text:link,
.more-btn-text:hover {
  color: #fff;
  text-decoration: none;
}

/*
==========================
    BUTTONS END
==========================
*/

/*
=========================
    BOX START
=========================
*/
.box-1 {
  width: 300px;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.5);
  margin: 10px auto;
  color: #222222;
  padding: 20px 20px 15px;
  transition: all 0.5s linear;
  border-bottom: 3px solid #112e57;
}

.box-2 {
  width: auto;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.5);
  margin: 10px auto;
  color: #222222;
  padding: 20px 20px 15px;
  border-bottom: 3px solid #222222;
  transition: all 0.5s linear;
}

.box-1:hover,
.box-2:hover {
  background-color: #222222;
  color: #fff;
  transition: all 0.5s linear;
}

.box-1-link:hover,
.box-2-link:hover {
  text-decoration: none;
}

/*
=========================
    BOX END
=========================
*/

/* Calendar */

.calendar {
  background-color: #fff;
  /* box-shadow: rgb(227, 233, 243) 0px 2px 4px 0px; */
  border-radius: 3px;
  margin: 10px;
}

.calendar-logo {
  width: 35%;
  margin: 10%;
}

.react-calendar {
  padding: 20px !important;
}
